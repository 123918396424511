/** @jsx jsx */

import { graphql } from "gatsby";
import { jsx, Container, Heading, Box, Flex, Text } from "theme-ui";
import Layout from "../components/global/Layout";
import { TagsRelated, Sharing, Author } from "../components/";

const ArticleTemplate = ({ data }) => {
	const { markdownRemark } = data;
	const {
		title,
		date,
		image,
		tags,
		related_article,
		author,
	} = markdownRemark.frontmatter;
	const { html } = markdownRemark;

	return (
		<Layout>
			<Box as="section" py={0}>
				<Container px={0}>
					<Box as="article">
						<Flex
							sx={{
								maxHeight: "55vh",
								overflow: "hidden",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<img src={image} alt={title} />
						</Flex>
						<Flex
							sx={{
								flexDirection: "column",
								alignItems: "flex-start",
								maxWidth: ["100%", "100%", "80%", "70%"],
							}}
							py={[3, 4, 5]}
							px={[4, 3, 4]}
							mx={["auto"]}
						>
							<Box>
								<Text variant="text.bodySmall" mb={[2, 3]}>
									{date}
								</Text>
								<Heading variant="text.headingSuper" mb={[3, 4]}>
									{title}
								</Heading>

								{/* {author && <Author author={author} />} */}
							</Box>
							<Box py={[2, 3, 4]}>
								<Heading as="h4" variant="text.headingXS">
									Share:
								</Heading>
								<Sharing articleSlug={markdownRemark.fields.slug} tw fb li />
							</Box>
							<Box>
								<Text
									variant="text.bodyBase"
									dangerouslySetInnerHTML={{ __html: html }}
								/>
							</Box>
							{tags && (
								<Box>
									<Heading variant="text.headingXS" as="h4">
										Tags:
									</Heading>
									<TagsRelated tags={tags} />
								</Box>
							)}

							<Box py={[2, 3, 4]}>
								<Heading as="h4" variant="text.headingXS">
									Share:
								</Heading>
								<Sharing articleSlug={markdownRemark.fields.slug} tw fb li />
							</Box>

							{/* {author && <Author author={author} showBio />} */}
						</Flex>
					</Box>
				</Container>
			</Box>
		</Layout>
	);
};

export default ArticleTemplate;

export const query = graphql`
	query SinglePageBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				image
				date(formatString: "MMMM DD, YYYY")
				tags
				author
			}
			fields {
				slug
			}
		}
	}
`;
